<template lang="pug">
.goods
  van-swipe.goods-swipe(:autoplay='3000')
    van-swipe-item(v-for='(thumb,index) in goods.thumb', :key='thumb')
      //- van-image(:src='thumb + swipeSize' height="280"  width='100%' fit="cover")
      dp-image.my-swipe(:src='thumb + `?x-oss-process=image/resize,h_560,w_${parseInt(560/(screenWidth*0.46) * screenWidth)},m_pad,limit_0`'  width='100%'
        :urls='goods.thumb'
        :index='index'
        )
  van-cell-group
    van-cell
      .goods-title {{ goods.title }}
      //- .van-sku-header__goods-info
        .van-sku__goods-price
          span.van-sku__price-symbol ￥
          span.van-sku__price-num {{ totalPrice }}
      .van-sku__goods-price(v-if='power') {{ selectSku ? `￥ ${(selectSku.price/100).toFixed(2)}` : sku.noramlPrice }}
      //- span 促销价
      //- .van-sku__goods-price(v-if='power') {{ selectSku ? `￥ ${(parseFloat(selectSku.factory_promotion_price)).toFixed(2)}` : '' }}
      //- .van-sku__goods-price(v-if='!power') 升级为公司销售员后查看价格
      //- template(#right-icon)
        van-icon(title='收藏' name="like-o" color='#000000' size='24' @click='onCollect') 
  van-cell-group
    van-field(name="checkbox" label="" label-width='60px')
      template(#input)
        span(v-if='!selectColor || !selectSize')
          span 请选择：{{ !selectColor ? '颜色' : '' }} {{ !selectSize ? '尺寸' : '' }}
        span(v-else style='color:red;') 已选择： 
          span {{ `${selectColor.name} ${selectSize.name}` }} 
      template(#right-icon)
        span(style='float:right;' v-if='power && select_sku') ￥ {{ selectSku.price }}
    //- van-cell(title="规格" is-link center)
    van-field(name="color" label="颜色" label-width='60px')
      template(#input)
          //- van-radio-group(v-model="radio" direction="horizontal")
          van-row(:gutter="10")
            van-col(v-for='(colorItem,colorIndex) in colors' :key='colorIndex')
              DpSkuItem(:state='colorItem.state' :label='colorItem.name' @click='onColorClick(colorItem)')
         
    van-field(name="size" label="尺寸" label-width='60px')
      template(#input)
        van-row(:gutter="10")
          van-col(v-for='(sizeItem,sizeIndex) in sizes' :key='sizeIndex')
            DpSkuItem(:state='sizeItem.state' :label='sizeItem.name' @click='onSizeClick(sizeItem)')
    van-field(name="num" label="数量" label-width='60px' v-if='power')
      template(#input)
        van-stepper(v-model="selectNum" integer button-size="26px")
    //- van-field(name="radio" label="可选配置")
      template(#input)
        van-radio-group(v-model="radio" direction="horizontal")
          van-radio(name="1") 单选框
          van-radio(name="2") 单选框
   
    //- van-field(
    //-   readonly
    //-   clickable
    //-   name="picker"
    //-   :value="value"
    //-   label="结算方式"
    //-   placeholder="点击选择结算方式"
    //-   @click="showPicker = true"
    //- )
    //- van-popup(v-model="showPicker" position="bottom")
    //-   van-picker(
    //-     show-toolbar
    //-     :columns="columns"
    //-     @confirm="onConfirm"
    //-     @cancel="showPicker = false"
    //-   )
    //- van-cell(title="选购数量" is-link center)

    //- van-cell(title="选择规格" is-link @click="show = !show" center)
      template(#label)
        span(v-if='sku.select_sku') {{ sku.select_sku.color }} {{ sku.select_sku.size }} {{ sku.select_sku.version }}
        span(v-else)
  van-cell-group
    van-cell(value='联系方式' icon='shop-o' is-link @click='onLookContact')
      template(slot='title')
        span.van-cell-text {{companyInfo.com_name}}的店
        van-tag.goods-tag(type='danger') 官方
  van-tabs(sticky scrollspy offset-top='40')
    van-tab(title='商品参数') 
      van-cell-group(title="商品参数")
        van-field(
          v-for="(item,index) of introText" :key="index"
          :label="item.label"
          :value="goods[item.key]"
          readonly
          )
    van-tab(title='图文介绍')
      van-cell-group(title="图文介绍")
        van-row
          van-col(:span="24")    
            dp-image.intro-pic(v-for='(img,index) in imageList' :key='index' :src='img' :urls='imageList' :index='index')
            //- img.intro-pic(v-for='img in imageList', v-lazy="img")
            //- img.intro-pic(v-for='img in imageList', v-lazy='img + `?x-oss-process=image/resize,w_${screenWidth},limit_0`')
    
    van-tab(v-if='power && pptImgs.length>0' title='PPT用图')
      van-cell-group(title="PPT用图")
        van-row
          van-col(:span="24")    
            dp-image.intro-pic(v-for='(img,index) in pptImgs' :key='index' :src='img' :urls='pptImgs' :index='index')
    van-row(style='margin:10px' v-if='goods.ppt_file_url && goods.ppt_file_name && power')
      van-col(:span="24")
        span(style='font-size:14px;') PPT文件：
        span(style='color:#2f85da;font-size:14px;' @click='onDownload(goods.ppt_file_url)' :harf='goods.ppt_file_url') {{ goods.ppt_file_name }}
    van-tab(title='商品推荐')
      van-cell-group(title="商品推荐")
        van-grid(:column-num='2' square)
          van-grid-item.dp-grid(
            v-for="(good, index) of recommendGoods"
            :key="index"
             @click="go(good.id)"
            )
            template(#default)
              //- van-image(:src="good.fur_image + previewSize" lasy-load  width="40vw" height="40vw" fit="cover")
              //- span.van-grid-item__text {{good.fur_name}}
              van-image(:src="good.fur_image + previewSize"  lasy-load width="38vw" height="38vw")
              span.van-grid-item__text(:title='good.fur_name') 
                span {{good.fur_name}}
        //- van-swipe(:width="140" :show-indicators="false")
        //-   van-swipe-item(v-for='(good,index) in recommendGoods' :key="index")
        //-     van-image.intro-pic(:src='good.fur_image' width="120" height="120" fit="cover" lasy-load @click="go(good.id)")
        //-     van-cell(:title='good.fur_name')

  div(v-if='power')
    van-goods-action
      component(
        v-for="(action,index) of actions"
        :is="action.component"  
        :type="action.type" 
        :key="index" 
        :icon='action.icon' 
        :color='action.color'
        @click='action.click'
        )
        | {{ action.name }}
  van-sku(
    v-model='show', 
    :sku='sku', 
    :goods='goods', 
    :goods-id='goodsId', 
    :quota='quota', 
    :hide-stock='sku.hide_stock',
    :initial-sku="initSku",
    :close-on-click-overlay='true',
    @buy-clicked='onBuyClicked', 
    @add-cart='onAddCartClicked',
    @sku-selected='onSelectSku'
    )
    template(#sku-header-price v-if='!power')
      //- .van-sku__goods-price(v-if='!power') 升级为公司销售员后查看价格
      .van-sku__goods-price

      //- .van-sku-header__goods-info
        .van-sku__goods-price
          span.van-sku__price-symbol 登录后查看价格
          //- span.van-sku__price-num 0.00
  div(v-if="step === 'confirm'")
    confirm(:rooms="rooms", :totalPrice="totalPrice")
  loading-view(:value="loadingState")
</template>


<script>
import {  Sku } from 'vant'
import { previewSize, swipeSize, introSize } from '../../lib/helpers/OssImageHelper'

import Confirm from './confirm'

export default {
  components: {
    [Sku.name]:Sku,
    Confirm
  },
  data() {
    return {
      loadingState: false, // 加载遮罩
      swipeSize,
      previewSize,
      introSize,
      step: 'detail',
      rooms: [{
        name: '',
        checkedGoods: []
      }],
      introText: [
        {
          label: '品牌',
          key: 'com_brand_name'
        },
        {
          label: '系列',
          key: 'companys_self_series_name'
        },
        {
          label: '编码',
          key: 'fur_num'
        },
        {
          label: '用途',
          key: 'ftt_name'
        },
        {
          label: '分类',
          key: 'sp_type_name'
        },
        {
          label: '型号',
          key: 'fur_code'
        },
        {
          label: '毛重',
          key: 'fur_weight'
        },
        {
          label: '体积',
          key: 'fur_volume'
        },
        {
          label: '空间',
          key: 'field_name'
        },
        {
          label: '风格',
          key: 'style_name'
        }
      ],
      actions: [
        {
          name: '收藏',
          component: 'van-goods-action-icon',
          icon: 'like-o',
          color: "#000000",
          click: this.onCollect
        },
        {
          name: '购物车',
          component: 'van-goods-action-icon',
          icon: 'cart-o',
          click: this.onClickCart,
        },
        {
          name: '加入购物车',
          component: 'van-goods-action-button',
          type: 'warning',
          click: this.onAddToCart
        }
      ],
      goodsId: 1,
      is_preference: false, // 当前商品是否被收藏
      preferenceid: 0,  // 收藏的id
      quota: 0, // 限购数
      goods: {
        // title: '美国伽力果（约680g/3个）',
        // price: 2680,
        // express: '免运费',
        // remain: 19,
        // default_sku: 0,
        // thumb: [
        //   'https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg',
        //   'https://img.yzcdn.cn/public_files/2017/10/24/1791ba14088f9c2be8c610d0a6cc0f93.jpeg',
        // ]
      },
      show: false,
      sku: {
        tree: [
          {
            k: '颜色', // skuKeyName：规格类目名称
            v: [
              {
                id: '0', // skuValueId：规格值 id
                name: '红色', // skuValueName：规格值名称
                // imgUrl: 'https://img.yzcdn.cn/1.jpg', // 规格类目图片，只有第一个规格类目可以定义图片
                previewImgUrl: 'https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg', // 用于预览显示的规格类目图片
              },
              {
                id: '1',
                name: '蓝色',
                // imgUrl: 'https://img.yzcdn.cn/public_files/2017/10/24/1791ba14088f9c2be8c610d0a6cc0f93.jpeg',
                previewImgUrl: 'https://img.yzcdn.cn/public_files/2017/10/24/1791ba14088f9c2be8c610d0a6cc0f93.jpeg'
              }
            ],
            k_s: 's1' // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
          },
          {
            k: '尺寸',
            v: [
              {
                id: '2',
                name: '2000*1500*750'
              },
              {
                id: '3',
                name: '2000*1600*750'
              }
            ],
            k_s: 's2'
          }
        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [
          {
            id: 1, // skuId，下单时后端需要
            price: 10001, // 价格（单位分）
            s1: '0', // 规格类目 k_s 为 s1 的对应规格值 id
            s2: '2', // 规格类目 k_s 为 s2 的对应规格值 id
            s3: '0', // 最多包含3个规格值，为0表示不存在该规格
            stock_num: 110 // 当前 sku 组合对应的库存
          },
          {
            id: 2, // skuId，下单时后端需要
            price: 10002, // 价格（单位分）
            s1: '0', // 规格类目 k_s 为 s1 的对应规格值 id
            s2: '3', // 规格类目 k_s 为 s2 的对应规格值 id
            s3: '0', // 最多包含3个规格值，为0表示不存在该规格
            stock_num: 110 // 当前 sku 组合对应的库存
          },
          {
            id: 3, // skuId，下单时后端需要
            price: 10003, // 价格（单位分）
            s1: '1', // 规格类目 k_s 为 s1 的对应规格值 id
            s2: '2', // 规格类目 k_s 为 s2 的对应规格值 id
            s3: '0', // 最多包含3个规格值，为0表示不存在该规格
            stock_num: 110 // 当前 sku 组合对应的库存
          }
        ],
        price1: '1.00', // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: true, // 是否隐藏剩余库存
        select_sku: null,   // 当前选中的sku
       },
      imageList: [
        // 'https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg',
        // 'https://img.yzcdn.cn/public_files/2017/10/24/1791ba14088f9c2be8c610d0a6cc0f93.jpeg'
      ],
      pptImgs: [],
      initSku: {
        s1: '',
        s2: '',
        s3: '',
        selectedNum: 1,
      },
      skuSample: [
        // {
        //   id: 1264,
        //   name: '刨花板-暖白色',
        //   thumbnail: '',
        //   color: '暖白色',
        //   size: '3000*3000*720',
        //   version: '',
        //   discount: 1000,
        //   inventory: 49
        // }
      ],
      totalPrice: 0,    // 当前选中商品的价格，如果未选中则显示区间价格
      recommendGoods:[], // 推荐商品
      colors: [],   // 可选颜色
      sizes: [],    // 可选规格
      selectColor: '',    // 当前选择的颜色
      selectSize: '',     // 当前选择的规格
      selectNum: '',      // 选择数量
    }
  },
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo
    },
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
    // 必须登录且已经注册为公司销售员
    power() {
      // return this.$store.getters.isLogined() && this.$store.state.userInfo.sell_users
      return this.$store.getters.isLogined() && this.$store.state.userInfo.sell_users && this.$store.state.userInfo.sell_users.state == "1" 
    },
    sellerInfo() {
      return this.$store.state.userInfo.sell_users
    },
    screenWidth() {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    },
    screenHeight() {
      return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    },
    devicePixelRatio() {
      return window.devicePixelRatio
    },
    selectSku() {
      // 当前选择的sku
      const res = this.skuSample.find(p => p.color == (this.selectColor ? this.selectColor.name : '') && p.size == (this.selectSize ? this.selectSize.name : ''))
      return res
    }
  },
  watch: {
    $route() {
      this.$emit('on-nav', {
        leftText: '返回',
        leftArrow: true,
        onClickLeft: () => {
          this.$router.back(-1)
        }
      })
    },
    is_preference:{
      handler(newVal) {
        if(newVal) {
          let action = this.actions.find((a) => {
            return a.name === '收藏'
          })
          action.icon = 'like'
          action.color = 'red'
          action.name = '已收藏'
        } else {
          let action = this.actions.find((a) => {
            return a.name === '已收藏'
          })
          action.icon = 'like-o'
          action.color = 'black'
          action.name = '收藏'
        }
      },
    }
  },
  methods: {
    async GetFurDetail () {
      const query = this.$route.query
      if (query.from) {
        const res = await this.$api.GetCompanyGoods(query.id)
        this.goodsId = res.data.fur_id
      } else {
        this.goodsId = query.id || 0
      }
   
      let param = {
        id: this.goodsId, 
        com_id:this.$store.state.routeInfo.comId
      }
      try {
        let res = await this.$api.GetFurDetail(param)
        let intros = res.data.furniture_intro_pics
        const {style_name, ...rest} = res.data 
        let temp_tag = style_name
        let style_tags = style_name.split("、")
        if (style_tags && style_tags.length>1) {
          style_tags = [...new Set(style_tags )]
          temp_tag = style_tags.join("、")
        }
        this.goods = {
          ...rest,
          style_name: temp_tag
        }
        this.goods.thumb = []
        this.goods.thumb = intros.filter( ({intro_type})=>{
          return intro_type === 'intro'
        }).map( ({fip_url}) =>{
          return fip_url
        })
        let {fur_name, fur_cost} = res.data
        this.goods.title = fur_name

        this.skuSample  = []
        this.skuSample = res.data.furniture_sku
        this.skuSample.map((sku) => {
          sku.price = sku.price * 100
        })
        this.calculateSku()
        
        this.imageList = []
        this.imageList = intros.filter( ({intro_type})=>{
          return intro_type === 'scene'|| intro_type === 'setup'
        })
        .sort((a, b) => {
          return a.order - b.order
        })
        .map(({fip_url}) =>{
          return fip_url
        })

        this.pptImgs = intros.filter(p => p.intro_type == "ppt").sort((a, b) => {
          return a.order - b.order
        }).map(({fip_url}) =>{return fip_url})

        this.goods.price = fur_cost
        this.initialSku()
        if(this.power) {
          this.getFurCollect()
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 初始化默认sku
    initialSku() {
      let sk_id = (((this.sku).select_sku) || 0).sk_id || 0
      if(sk_id == 0) sk_id = this.goods.sku_poi_furniture_sku
      let defaultsk = this.sku.list.find(p=>p.id === sk_id)
      if(!defaultsk) {
        defaultsk = this.sku.list[0]
      }
      this.sku.select_sku = this.skuSample.find(p=> p.sk_id === defaultsk.id)
      this.initSku = {
        s1: defaultsk.s1,
        s2: defaultsk.s2,
        s3: defaultsk.s3,
        selectedNum: 1,
      }

      const sc = this.colors.find(p => p.name == this.sku.select_sku.color)
      if (sc) {
        this.onColorClick(sc)
      }

      const ss = this.sizes.find(p => p.name == this.sku.select_sku.size)
      if (sc) {
        this.onSizeClick(ss)
      }
    },

    setTotalPrice() {
      if(this.sku.select_sku) {
        this.totalPrice ='￥' + parseFloat(this.sku.select_sku.price * 0.01).toFixed(2)
      } else {
        this.totalPrice = this.sku.noramlPrice
      }
    },

    onClickCart() {
      this.$router.push('cart')
    },

    onSelectSku({selectedSkuComb}) {
      // 选中完整的sku
      if(selectedSkuComb != null) {
        let sk_id = selectedSkuComb.id
        this.sku.select_sku = this.skuSample.find(p=> p.sk_id === sk_id)
      }else{
        this.sku.select_sku = null
      }
      this.setTotalPrice()
    },

    getPower() {
      if(!this.power) {
        this.$toast('请先登录')
      }
      return this.power 
    },

    // 立即购买
    async onBuyClicked(skuData) {
      if(!this.getPower()) {
        return false
      }
      let { selectedSkuComb} = skuData
      let sk_id = selectedSkuComb.id
      if(this.sku.select_sku != null) {
        await this.onAddCartClicked(skuData,true)
        let checkedGoods = [sk_id]
        this.$router.push({
            name: 'cart',
            query: {
              goods: checkedGoods
            }})
        // this.$toast('立即购买,跳转到清单页面')
      }else{
        this.$toast("请选择规格信息")
      }
    },

    // 添加到购物车
    async onAddCartClicked(skuData,buyNow) {
      if(!this.getPower()) {
        return false
      }
      if (!this.selectSku) {
        this.$toast('请选择规格信息')
        return
      }
      let {goodsId, selectedSkuComb, selectedNum} = skuData
      let sk_id = selectedSkuComb.id
      let items = [{
        sku_id: sk_id,
        fur_id: goodsId,
        price: selectedSkuComb.price / 100,
        num: selectedNum,
        remark: ''
      }]
      let param = {
        customer_id: 0,
        items: JSON.stringify(items),
        name: '',
        seller_poi_sell_user: this.userId, // TODO: 加入购物和接口需要传用户id 字段是销售id
        seller_poi_users: this.userId,
        seller_poi_sell_users: this.sellerId,
        type: 'online'
      }
      try{
        await this.$api.AddToCart(param)
        if(!buyNow) this.$toast('已成功添加到购物车')
      }catch (ex){
        this.$toast(this.$error(ex))
      }
      this.show = false
    },

    // 收藏、取消收藏商品
    async onCollect() {
      try {
        if(!this.is_preference){
          let param = {
            point: this.goodsId,
            type: 'fur',
            action: 'favor',
            fur_type: 'online',
            user_poi_users: this.userId,
            seller_poi_sell_users: this.sellerId
          }
          let res = await this.$api.AddToCollect(param)
          this.preferenceid = res.data.id
          this.$toast('收藏成功')
          this.is_preference = true
        }else{
          await this.$api.RemoveToCollect(this.preferenceid)
          this.$toast('取消收藏')
          this.is_preference = false
          this.preferenceid = 0
        }
      } catch (ex) {
          this.$toast(this.$error(ex))
      }
    },

    calculateSku () {
      // 定义树结构
      let tree = [{
        k_s: 's1',
        k: '颜色',
        v: []
      }, {
        k_s: 's2',
        k: '尺寸',
        v: []
      }, {
        k_s: 's3',
        k: '其他规格',
        v: []
      }]

      // 分别分离颜色、尺寸、其他规格
      let colors = []
      let sizes = []
      let versions = []
      this.skuSample.map((sku, index) => {
        if (!colors.find((color) => {
          return color.name === sku.color
        })) {
          colors.push({
            id: index + '',
            name: sku.color,
            previewImgUrl: sku.thumbnail
          })
        }

        if (!sizes.find((size) => {
          return size.name === sku.size
        })) {
          sizes.push({
            id: index + '',
            name: sku.size
          })
        }

        if (!versions.find((version) => {
          return version.name === sku.version
        })) {
          if (sku.version) {
            versions.push({
              id: index + '',
              name: sku.version
            })
          }
        }
      })

      // 为sku结构赋值
      tree[0].v = colors
      tree[1].v = sizes
      if (versions.length > 0) {
        tree[2].v = versions
      } else {
        tree.splice(2)
      }

      // 基于sku结构，重新遍历sku原始数据构建list
      let list = this.skuSample.map((sku) => {
        let s1 = colors.find((color) => {
          return color.name === sku.color
        }).id
        let s2 = sizes.find((size) => {
          return size.name === sku.size
        }).id
        let s3 = (versions.find((version) => {
          return version.name === sku.version
        }) || {}).id || '0'
        return {
          id: sku.sk_id, // skuId，下单时后端需要
          price: parseInt(sku.price), // 价格（单位分）
          s1, // 规格类目 k_s 为 s1 的对应规格值 id
          s2, // 规格类目 k_s 为 s2 的对应规格值 id
          s3, // 最多包含3个规格值，为0表示不存在该规格
          stock_num: 100000//sku.inventory // 当前 sku 组合对应的库存
        }
      })      
      
      // 构建sku数据
      let sku = {
        tree,
        list,
        none_sku: false,
        hide_stock: true
      }
      // 赋值
      this.sku = sku

      let prices = sku.list.map(({price}) => parseFloat(price*0.01)).sort((a,b)=> a-b)
      if(prices && prices.length > 0) {
        this.sku.noramlPrice = '￥' + `${prices[0].toFixed(2)}` + ' ~ ￥' + `${prices[prices.length-1].toFixed(2)}`   
      } else {
        this.sku.noramlPrice = '￥' + '0.00'
      }
      const tempColors = [...new Set(this.skuSample.map(({color})=>color))]
      this.colors = tempColors.map(p => {
        return {
          name: p ? p : '默认',
          state: 0
        }
      })
      // if (this.colors.length == 1) {
      //   this.colors[0].state = 1
      // }
      
      const tempSizes = [...new Set(this.skuSample.map(({size})=>size))]
      this.sizes = tempSizes.map(p => {
        return {
          name: p ? p : '常规',
          state: 0
        }
      })
    },

    // 获取当前家具收藏的状态 只能针对商品收藏不可以收藏sku
    async getFurCollect() {
       let param = {
        where : {
          type: 'fur',
          point: this.goodsId,
          user_poi_users: this.userId,
          seller_poi_sell_users: this.sellerId
        }
      }
      let res = await this.$api.GetFurCollect(param)
      this.is_preference =  res.data.count != 0
      this.preferenceid = this.is_preference  ? res.data.items[0].id : 0
      // console.log('当前家具是否被收藏'+this.is_preference,this.preferenceid)
    },

    async GetRecommendGoods(){
      try{
        let res = await this.$api.GetFurForCom(this.goodsId)
        let tempArr = []
        for (let i = 0; i < res.data.count; i++) {
          const {fur_id_poi_furnitures} = res.data.items[i]
          if (fur_id_poi_furnitures && fur_id_poi_furnitures.id != this.goodsId && fur_id_poi_furnitures.fur_states == "up") {
            tempArr.push({...fur_id_poi_furnitures})
          }
        }
        tempArr  = [...new Set(tempArr)]
        if (tempArr.length > 0) {
          this.recommendGoods = this.getRandomArrayElements(tempArr,tempArr.length > 10 ? 10 : tempArr.length)
        }
      }catch(ex){
        console.log(11111, ex)
        // this.$toast(this.$error(ex))
      }
    },

    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },

    go(id) {
      this.$router.push({name: 'detail', query: {id: id}})
      // this.$router.go(0)
    },

    async onShare() {
      let url = location.href.split('#')[0].toString() //url不能写死
      let res = (await this.$api.GetWeChat({url})).data
      return new Promise((resolve, reject) => {
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx1ee5e66769259cca', // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表 
        })
        const shareConfig = {
          imgUrl: this.goods.fur_image,
          desc: this.$store.state.companyInfo.com_name,
          title: this.goods.title,
          link: url
        }
        window.wx.ready(()=> {   //需在用户可能点击分享按钮前就先调用
          window.wx.updateAppMessageShareData(shareConfig)
          window.wx.updateTimelineShareData(shareConfig)
          resolve()
        })

        resolve()

        window.wx.error((res) => {
          reject(res)
        })
      })
    },

    onLookContact() {
      const { com_owner, mobile, com_tel} = this.companyInfo
      this.$dialog.alert({
        message: `联系人: ${com_owner} \n\n联系电话: ${mobile} \n\n公司电话: ${com_tel}`,
        messageAlign: 'left',
        theme: 'round-button',
        closeOnClickOverlay: true
      }).then(()=>{}).catch(()=>{});
    },

    // 下载附件
    onDownload(url, name) {
      window.open(url, '_blank')
      // var link = document.createElement('a')
      // link.style.display = 'none'
      // link.href = url
      // link.setAttribute('download', name)
      // document.body.appendChild(link)
      // link.click()
      // // link.remove()
      // document.body.removeChild(link)
    },

    onColorClick(obj) {
      if (obj.state == -1 ) return
      try {
        if (obj.state == 0) {
          if (this.selectColor) {
            this.selectColor.state = 0
          }
          this.selectColor = obj
          obj.state = 1
        } else if (obj.state == 1) {
          obj.state = 0
          this.selectColor = null
        }
        this.refreshSizes()
      } catch (error) {
        console.log(error)
      }
    },

    // 选择颜色后刷新可选规格
    refreshSizes() {
      if (this.selectColor) {
        const canSelectSku = this.skuSample.filter(p => p.color == this.selectColor.name)
        const tempArr = canSelectSku.map(({size})=>size)
        this.sizes.forEach(item => {
          const inList = tempArr.find(p => p == item.name)
          if (inList && item.state == -1) {
            item.state = 0
          } else if (!inList && item.state != -1) {
            item.state = -1
          }
        })
      } else {
        this.sizes.forEach(item => {
          item.state = item.state == -1 ? 0 : item.state
        })
      }
    },

    onSizeClick(obj) {
      if (obj.state == -1) return
      try {
        if (obj.state == 0) {
          if (this.selectSize) {
            this.selectSize.state = 0
          }
          this.selectSize = obj
          obj.state = 1
        } else if (obj.state == 1) {
          obj.state = 0
          this.selectSize = null
        }
        this.refreshColors()
      } catch (error) {
        console.log(error)
      }
    },

    // 选择规格后刷新可选颜色
    refreshColors() {
      if (this.selectSize) {
        const canSelectSku = this.skuSample.filter(p => p.size == this.selectSize.name)
        const tempArr = canSelectSku.map(({color})=>color)
        this.colors.forEach(item => {
          const inList = tempArr.find(p => p == item.name)
          if (inList && item.state == -1) {
            item.state = 0
          } else if (!inList && item.state != -1) {
            item.state = -1
          }
        })
      } else {
        this.colors.forEach(item => {
          item.state = item.state == -1 ? 0 : item.state
        })
      }
    },

    // 添加到购物车
    async onAddToCart() {
      if(!this.getPower()) {
        return false
      }
      if (!this.selectSku) {
        this.$toast('请选择规格信息')
        return
      }
      const {sk_id, fur_id_poi_furnitures,price } = this.selectSku
      let items = [{
        sku_id: sk_id,
        fur_id: fur_id_poi_furnitures,
        price: price / 100,
        num: this.selectNum,
        remark: ''
      }]
      let param = {
        customer_id: 0,
        items: JSON.stringify(items),
        name: '',
        seller_poi_sell_user: this.userId, // TODO: 加入购物和接口需要传用户id 字段是销售id
        seller_poi_users: this.userId,
        seller_poi_sell_users: this.sellerId,
        type: 'online'
      }
      try{
        await this.$api.AddToCart(param)
         this.$toast('已加入购物车!')
      }catch (ex){
        this.$toast(this.$error(ex))
      }
    },
  },
  // activated() {
  //   this.$emit('on-nav', {
  //     leftText: '返回',
  //     rightText: '主页',
  //     leftArrow: true,
  //     rightArrow: true,
  //     onClickLeft: () => {
  //       this.$router.back(-1)
  //     },
  //     onClickRight: () => {
  //       this.$router.push({name: 'home'})
  //     }
  //   })
  // },

  async mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      rightText: '主页',
      leftArrow: true,
      rightArrow: true,
      onClickLeft: () => {
        this.$router.back(-1)
      },
      onClickRight: () => {
        this.$router.push({name: 'home'})
      }
    })
    this.loadingState = true
    try {
      await this.GetFurDetail()
      if(this.goodsId != 0){
        await this.GetRecommendGoods()
      }
    }catch(ex) {
      this.$toast(this.$error(ex))
    }
    // try{
    //   await this.onShare()
    // }catch(ex) {
    //   // this.$toast(new Error('请刷新页面'))
    // }
    this.loadingState = false  
  }
}
</script>

<style scoped lang="less" scoped>
.my-swipe {
  height:40vw;
}
.goods {
  padding-bottom: 50px;
  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }
  &-title {
    font-size: 16px;
  }
  &-price {
    color: #f44;
  }
  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }
  &-cell-group {
    margin: 15px 0;
    .van-cell__value {
      color: #999;
    }
  }
  &-tag {
    margin-left: 5px;
  }
}
.intro-pic {
  width: 100%;
  height: auto;
  margin-top: -5px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.dp-grid {
  .van-grid-item__content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .van-grid-item__text {
    width: 100%;
    margin:auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align:center;
    vertical-align: middle;
  }
  .col {
    color: #c6cad1;
  }
}
</style>